import create, { State } from "zustand";
import { persist } from "zustand/middleware";

import { categories, regions, attributes } from "utils/constants";
import StorageService from "utils/storage";
import { request } from "utils/request";

const filterRawData = {
  category: categories,
  region: regions,
  facilities: attributes,
};

interface Store extends State {
  user: any;
  setUser: (value: any) => void;
  token: string;
  setToken: (value: string) => void;
  filterData: any;
  filterSearchTerm: string;
  filterObject: {
    category: string[];
    region: string[];
    facilities: string[];
  };
  filterCount: number;
  campList: any;
  loginModalOpen: boolean;
  cookieSeen: boolean;
  setCookieSeen: (value: boolean) => void;
  setCampList: (value: any) => void;
  setFilter: (value: any) => void;
  clearFilter: () => void;
  setFilterCount: (count: number) => void;
  setFilterSearchTerm: (term: string) => void;
  toggleLoginModal: any;
  signOut: () => void;
  isFavorited: boolean;
  isFutured: boolean;
  isVisited: boolean;
  favoriteList: any;
  pastTripList: any;
  futureList: any;
  getFavoritesStatus: (id: string) => void;
  getFavoritesList: () => void;
  toggleFavorites: (id: string, endpoint: string, key: string) => void;
  selectedListType: string;
  setSelectedListType: (type: string) => void;
}

const useStore = create<Store | any>(
  persist(
    (set, get) => ({
      token: "",
      setToken: (value: any) => set(() => ({ token: value })),

      user: {},
      setUser: (value: any) => set(() => ({ user: value })),

      cookieSeen: false,
      setCookieSeen: (cookieSeen: boolean) => set(() => ({ cookieSeen })),

      filterData: JSON.parse(JSON.stringify(filterRawData)),
      filterSearchTerm: "",
      setFilterSearchTerm: (term: string) =>
        set(() => ({ filterSearchTerm: term })),
      filterObject: {
        category: [],
        region: [],
        facilities: [],
      },
      setFilter: (data: any) => set(() => ({ filterObject: data })),
      filterCount: 0,
      setFilterCount: (count: number) => set(() => ({ filterCount: count })),

      campList: [],
      setCampList: (data: any) => set(() => ({ campList: data })),

      loginModalOpen: false,
      toggleLoginModal: () =>
        set(() => ({ loginModalOpen: !get().loginModalOpen })),

      isFavorited: false,
      isFutured: false,
      isVisited: false,
      favoriteList: [],
      pastTripList: [],
      futureList: [],
      getFavoritesStatus: async (campingId: string) => {
        const [isFavorited, isFutured, isVisited] = await Promise.all([
          request("isAddedFavori", "POST", { campingId }),
          request("isAddedFuture", "POST", { campingId }),
          request("isAddedVisited", "POST", { campingId }),
        ]);
        set(() => ({
          isFavorited: isFavorited?.data?.status,
          isFutured: isFutured?.data?.status,
          isVisited: isVisited?.data?.status,
        }));
      },
      getFavoritesList: async () => {
        const [favoriteList, pastTripList, futureList] = await Promise.all([
          request('favoriList', 'GET'),
          request('visitedList', 'GET'),
          request('futureList', 'GET'),
        ]);
        set(() => ({
          favoriteList: favoriteList?.data,
          pastTripList: pastTripList?.data,
          futureList: futureList?.data,
        }));
      },
      toggleFavorites: async (campingId: string, endpoint: string, key: string) => {
        const actionType = endpoint.includes("add") ? "add" : "remove";
        const response = await request(endpoint, "POST", { campingId });
        if (response?.data?.status) {
          set(() => ({ [key]: actionType === "add" }));
        } else {
          set(() => ({ [key]: false }));
        }
      },
      isFavoritePlace: () => {
        return get().isFavorited || get().isFutured || get().isVisited;
      },

      clearFilter: () => {
        set({
          filterData: JSON.parse(JSON.stringify(filterRawData)),
          filterSearchTerm: "",
          filterCount: 0,
          filterObject: {
            category: [],
            region: [],
            facilities: [],
          },
        });
      },

      selectedListType: 'camping',
      setSelectedListType: (type: string) => set(() => ({ selectedListType: type })),
      
      signOut: () => {
        StorageService.clearUserData();
        return set(() => ({ user: {}, token: "" }));
      },
    }),
    {
      name: "customer-storage",
      getStorage: () => localStorage,
      partialize: (state) => ({
        token: state.token,
        user: state.user,
        cookieSeen: state.cookieSeen,
      }),
    }
  )
);

export default useStore;
