import { useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";

import { request } from "utils/request";
import { validateEmail } from "utils/validator";

interface SubmitButtonProps {
  disabled: boolean;
}

interface Props {
  signUp: (param: any) => void;
}

export const RegisterForm = ({ signUp }: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");

  const onEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const onFullnameChange = (e: any) => {
    setFullName(e.target.value);
  };

  const signInHandler = async () => {
    if (validateEmail(email) && password) {
      const params = { email, password, fullName };
      const { data } = await request("register", "POST", params);
      if (data?.status) {
        signUp(data);
      } else {
        toast("Lütfen bilgilerinizi kontrol edin", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    }
  };

  return (
    <Section>
      <StyledInput placeholder={"İsim Soyisim"} onChange={onFullnameChange} />
      <StyledInput placeholder={"Email"} onChange={onEmailChange} />
      <StyledInput
        placeholder={"Şifre"}
        onChange={onPasswordChange}
        type={"password"}
      />
      <InfoText>
        Oturum açarak{" "}
        <LinkText
          aria-label="Terms"
          rel="noopener"
          href="https://kampp.in/user-agreement"
          target="_blank"
        >
          Şartlarımızı
        </LinkText>{" "}
        ve{" "}
        <LinkText
          aria-label="Privacy"
          rel="noopener"
          href="https://kampp.in/privacy.html"
          target="_blank"
        >
          Gizlilik Politikasını
        </LinkText>{" "}
        kabul ediyorsun.
      </InfoText>
      <SubmitButton
        disabled={!(validateEmail(email) && password !== "" && fullName !== "")}
        onClick={signInHandler}
      >
        Devam Et
      </SubmitButton>
    </Section>
  );
};

const Section = styled.div`
  &:last-child {
    border-bottom: none !important;
  }
`;

const StyledInput = styled.input`
  outline: none;
  border: none;
  margin: 12px 0px 8px 0px;
  padding: 15px;
  width: 100%;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 4px;
  font-size: 14px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  &:first-child {
    margin-top: 24px;
    margin-bottom: 0px;
  }
`;

const SubmitButton = styled.button<SubmitButtonProps>`
cursor: pointer;
display: inline-block;
position: relative;
text-align: center;
text-decoration: none;
touch-action: manipulation;
font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
font-size: 16px;
line-height: 20px;
font-weight: 600;
border-radius: 4px;
outline: none;
padding: 14px 24px;
margin: 12px 0px;
transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
-webkit-tap-highlight-color: transparent;
border: none;
background: ${(props) => (props.disabled ? "gray" : "#ee7b21")};

color: rgb(255, 255, 255);
width: 100%;
}
`;

const InfoText = styled.span`
  color: #919191;
  font-size: 12px;
  line-height: 16px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-weight: 400;
`;

const LinkText = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;
