import styled from "styled-components";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUser, faHeart, faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { useRouter } from "next/router";
import useStore from "hooks/useStore";

const TabBar = () => {
  const { pathname, push } = useRouter();
  const { toggleLoginModal, user } = useStore();

  const profileButtonHandler = () => {
    if (user.id) {
      push('/profile')
    } else {
      toggleLoginModal()
    }
  }

  return (
    <Container>
      <Link href="/" passHref>
        <TabItem>
          <TabIcon active={(pathname === '/').toString()} icon={faSearch} />
          <TabText>Keşfedin</TabText>
        </TabItem>
      </Link>
      <Link href="/makaleler" passHref>
        <TabItem>
          <TabIcon active={(pathname.includes('blog')).toString()} icon={faNewspaper} />
          <TabText>Makaleler</TabText>
        </TabItem>
      </Link>
      <Link href="/etkinlikler" passHref>
        <TabItem>
          <TabIcon active={(pathname.includes('blog')).toString()} icon={faNewspaper} />
          <TabText>Etkinlikler</TabText>
        </TabItem>
      </Link>
      {user.id && <Link href="/favorites" passHref>
        <TabItem>
          <TabIcon active={(pathname === '/favorites').toString()} icon={faHeart} />
          <TabText>Listelerim</TabText>
        </TabItem>
      </Link>}
      <TabItem onClick={profileButtonHandler}>
        <TabIcon active={(pathname === '/profile').toString()} icon={faUser} />
        <TabText>Profil</TabText>
      </TabItem>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: white;
  border-top: 1px solid #EBEBEB !important;
  display: none;
  @media (max-width: ${(props) => props.theme.size.laptop}) {
    display: flex;
  }
`

const TabItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 15px;
  cursor: pointer;
`

const TabText = styled.span`
  font-size: 10px;
  line-height: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  overflow-wrap: break-word;
  margin-top: 5px;
`

const TabIcon = styled(FontAwesomeIcon)<{active: string}>`
  width: 15px;
  height: 15px;
  color: ${(props) => props.active === 'true' ? props.theme.main.primary : 'rgb(167, 167, 167)'};
`;

export default TabBar;