import { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import useStore from "hooks/useStore";
import StorageService from "utils/storage";
import { request } from "utils/request";

import { LoginForm } from "components/Auth/LoginForm";
import { RegisterForm } from "components/Auth/RegisterForm";

const customStyles: any = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
    backgroundColor: "rgba(20, 20, 20, 0.7)",
  },
  content: {
    position: "absolute",
    borderRadius: "12px",
    background: "rgb(255, 255, 255)",
    overflow: "none",
    outline: "none",
    left: 0,
    right: 0,
    margin: "0px auto",
    padding: 0,
    width: "600px",
    height: "90vh",
    top: "50px",
    display: 'flex',
    flexDirection: 'column'
  },
};
Modal.setAppElement("#main");

const Login = () => {
  const { loginModalOpen, toggleLoginModal, setUser, setToken } = useStore();

  const [formType, setFormType] = useState("login");

  const setUserData = (data: any) => {
    StorageService.setAuthData(data.token);
    StorageService.setUserData(data.data);
    setUser(data.data);
    setToken(data.token)
  }

  const signIn = async (data: any) => {
    setUserData(data)
    toggleLoginModal();
  };

  const signUp = async (data: any) => {
    setUserData(data)
    toggleLoginModal();
  };

  const signInWithGoogle = async (response: any) => {
    if (response.googleId) {
      const { email, name, googleId, imageUrl } = response.profileObj;
      const postData = {
        email,
        googleId,
        fullName: name,
        profilePicture: imageUrl,
      };
      const { data } = await request("loginWithGoogle", "POST", postData);
      if (data?.status) {
        StorageService.setAuthData(data.token);
        setUser(data.data);
        toggleLoginModal();
      }
    }
  };

  const signInWithFacebook = async (response: any) => {
    if (response.accessToken) {
      const bodyData = {
        token: response?.accessToken,
      };
      const { data } = await request("loginWithFacebook", "POST", bodyData);
      if (data.status) {
        StorageService.setAuthData(data.token);
        setUser(data.data);
        toggleLoginModal();
      }
    }
  };

  const setFormTypeHandler = () => {
    const type = formType === "login" ? "register" : "login";
    setFormType(type);
  };

  return (
    <Modal
      isOpen={loginModalOpen}
      onRequestClose={toggleLoginModal}
      style={customStyles}
      className="kampp-modal"
      contentLabel="Filter Modal"
      closeTimeoutMS={250}
    >
      <HeaderContainer>
        <CloseIcon onClick={toggleLoginModal} icon={faTimes} />
        <HeaderTitle> Oturum açın veya kaydolun</HeaderTitle>
        <Gap />
      </HeaderContainer>

      <ContentContainer>
        <SectionTitle>{"Kampp'a Hoş Geldiniz"}</SectionTitle>

        {formType === "login" ? (
          <LoginForm signIn={signIn} />
        ) : (
          <RegisterForm signUp={signUp} />
        )}

        <SeparatorContainer>
          <Text>veya</Text>
          <BorderLine />
        </SeparatorContainer>
      </ContentContainer>
      <FooterContainer>
        <SocialButtonContainer>
          <FacebookLogin
            //@ts-ignore
            appId={process.env.NEXT_PUBLIC_FACEBOOK_ID}
            callback={signInWithFacebook}
            render={(renderProps) => (
              <SocialButton onClick={renderProps.onClick}>
                <SocialIcon
                  src={"/images/icons/facebook.png"}
                  width={20}
                  height={20}
                />
                Facebook ile devam et
                <SocialIconGap />
              </SocialButton>
            )}
          />
          <GoogleLogin
            //@ts-ignore
            clientId={process.env.NEXT_PUBLIC_CLIENT_ID}
            render={(renderProps) => (
              <SocialButton onClick={renderProps.onClick}>
                <SocialIcon
                  src={"/images/icons/google.png"}
                  width={20}
                  height={20}
                />
                Google ile devam et
                <SocialIconGap />
              </SocialButton>
            )}
            buttonText="Google ile devam et"
            onSuccess={signInWithGoogle}
            isSignedIn={false}
            cookiePolicy={"single_host_origin"}
            scope="email profile"
          />
        </SocialButtonContainer>
        <SignUpContainer>
          <Text>
            {formType === "login" ? "Hesabınız yok mu?" : "Hesabınız var mı?"}{" "}
            <LinkText
              onClick={setFormTypeHandler}
              aria-label="signup"
              rel="noopener"
            >
              {formType === "login" ? "Üye Olun" : "Giriş Yapın"}
            </LinkText>
          </Text>
        </SignUpContainer>
      </FooterContainer>
    </Modal>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  border-bottom: 1px solid rgb(235, 235, 235) !important;
  padding: "0px 20px";
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 24px;
  flex: 1;
`;

const ContentContainer = styled.div`
  padding: 24px 24px 28px 24px;
`;

const SectionTitle = styled.span`
  font-size: 22px;
  line-height: 26px;
  color: rgb(34, 34, 34);
  font-weight: 600;
`;

const LinkText = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

const SeparatorContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  position: relative;
`;

const Text = styled.span`
  color: #919191;
  font-size: 12px;
  line-height: 16px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-weight: 400;
  text-align: center;
  background-color: white;
  padding: 0px 10px;
`;

const BorderLine = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 8px;
  border: 1px solid rgb(235, 235, 235) !important;
  z-index: -1;
`;

const SocialButtonContainer = styled.div`
  padding: 12px 24px 28px 24px;
`;

const SocialButton = styled.button`
  cursor: pointer !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  touch-action: manipulation !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif !important;
  color: rgb(72, 72, 72) !important;
  padding: 10px 12px !important;
  border-radius: 8px !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: rgb(176, 176, 176) !important;
  background: transparent !important;
  box-shadow: none !important;
  min-width: 71.1935px !important;
  width: 100% !important;
  margin-bottom: 12px;
`;

const SocialIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const SocialIconGap = styled.div`
  width: 20px;
  height: 20px;
`;

const HeaderTitle = styled.span`
  color: rgb(34, 34, 34);
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 5px 5px;
`;

const Gap = styled.div``;

const SignUpContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default Login;
