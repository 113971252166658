export const SearchSelector = (data: any) => {
  const formattedData: any = [];
  const newData = data;
  const keys = Object.keys(newData);
  keys.forEach(key => {
    if (newData[key].length > 0) {
      newData[key].forEach((innerKey: any) => {
        const object = Object.assign(
          {},
          {
            ...innerKey,
            key: key,
          }
        );
        formattedData.push(object);
      });
    }
  });

  return formattedData;
}
