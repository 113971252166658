import styled from "styled-components";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ColumnInput {
  center?: boolean;
}

const Footer = () => {
  return (
    <Container>
      <InnerContainer>
        <ColumnContainer style={{marginRight: '20px'}}>
          <Logo src="/logo.png" alt="logo" width="200" height="100" />
          <FooterText>
            Kampp kullanıcı bildirimleriyle sürekli gelişen oldukça pratik bir
            kamp rehberidir.
          </FooterText>
          <FooterText>#kampplaplanla</FooterText>
          <IconContainer>
            <Link href="https://www.facebook.com/Kampp.in/" passHref>
              <a target="_blank" rel="noopener" aria-label="Facebook">
                <SocialIcon icon={["fab", "facebook-square"]} />
              </a>
            </Link>
            <Link href="https://www.instagram.com/kampp/" passHref>
              <a target="_blank" rel="noopener" aria-label="Instagram">
                <SocialIcon icon={["fab", "instagram"]} />
              </a>
            </Link>
            <Link href="https://twitter.com/kamppapp" passHref>
              <a target="_blank" rel="noopener" aria-label="Twitter">
                <SocialIcon icon={["fab", "twitter"]} />
              </a>
            </Link>
          </IconContainer>
        </ColumnContainer>
        <ColumnContainer center>
          <FooterTitle>Kampp App</FooterTitle>
          <FooterText>Uygulamamızı indirmeyi unutmayın!</FooterText>
          <AppContanier>
            <ALink
              aria-label="App Store"
              rel="noopener"
              href="https://apps.apple.com/us/app/kampp/id1269000420"
              target="_blank"
            >
              <StoreIcon
                src="/images/appstore-tr.svg"
                alt="appstore"
                width="200"
                height="100"
              />
            </ALink>
            <ALink
              aria-label="Play Store"
              rel="noopener"
              href="https://play.google.com/store/apps/details?id=com.kamp"
              target="_blank"
            >
              <StoreIcon
                src="/images/playstore-tr.png"
                alt="playstore"
                width="200"
                height="100"
              />
            </ALink>
          </AppContanier>
        </ColumnContainer>
      </InnerContainer>
      <InfoContainer>
        <div>
          <FooterInfoText>
            © {new Date().getFullYear()} Kampp, Inc
          </FooterInfoText>
          <ALink
            aria-label="Privacy"
            rel="noopener"
            href="/privacy.html"
            target="_blank"
          >
            <FooterInfoText>· Gizlilik</FooterInfoText>
          </ALink>
          <ALink
            aria-label="Kvkk"
            rel="noopener"
            href="/kvkk.html"
            target="_blank"
          >
            <FooterInfoText>· KVKK</FooterInfoText>
          </ALink>
        </div>
        <ALink
          aria-label="freepik"
          rel="noopener"
          href="https://www.freepik.com"
          target="_blank"
        >
          <FooterInfoText id="footerInfo">
            Cover image and some icons created by freepik
          </FooterInfoText>
        </ALink>
      </InfoContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: rgb(240, 240, 240);
  margin-bottom: 0px;
  @media (max-width: ${(props) => props.theme.size.laptop}) {
    margin-bottom: 145px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 48px 15%;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    flex-direction: column;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 15%;
  padding: 24px 0px;
  border-top: 1px solid rgb(216, 216, 216);
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    margin: 0px;
    flex-direction: column;
    &:last-child {
      #footerInfo {
        margin-top: 20px;
      }
    }
  }
`;

const ColumnContainer = styled.div<ColumnInput>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.center ? "center" : "flex-start")};
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    align-items: center;
    margin-bottom: 80px;
    width: 100%;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const Logo = styled.img`
  width: 200px;
  height: auto;
  object-fit: contain;
`;

const FooterTitle = styled.span`
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  color: rgb(34, 34, 34);
`;

const FooterText = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: rgb(34, 34, 34);
  margin-top: 24px;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    text-align: center;
  }
`;

const FooterInfoText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: rgb(34, 34, 34);
  margin-right: 10px;
`;

const AppContanier = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 24px;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    flex-direction: column;
  }
`;

const ALink = styled.a``;

const StoreIcon = styled.img`
  width: 200px;
  height: auto;
  margin-right: 10px;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    margin-top: 10px;
  }
`;

const IconContainer = styled.div`
  margin-top: 24px;
  cursor: pointer;
`;

const SocialIcon = styled(FontAwesomeIcon)`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

export default Footer;
