import { useState, useEffect, useRef, useCallback, memo } from "react";
import styled from "styled-components";
import { useRouter } from "next/router";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faMapMarkerAlt,
  faCampground,
} from "@fortawesome/free-solid-svg-icons";

import { request } from "utils/request";
import { SearchSelector } from "selectors";
import { useClickOutside } from "hooks/useClickOutside";

interface Props {
  scrollPosition?: number;
}

interface SCProps {
  scrollPosition: any;
  isHome?: boolean;
}

const Search = ({ scrollPosition }: Props) => {
  const { pathname } = useRouter();

  const [searchTerm, setSearchTerm] = useState("");
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [showAutoComplete, setShowAutoCoplete] = useState(false);

  const closeAutoComplete = () => setShowAutoCoplete(false);

  const searchRef: any = useRef("");
  useClickOutside(searchRef, closeAutoComplete);

  const searchHandler = useCallback(async () => {
    try {
      const { data } = await request(
        `search?query=${searchTerm}`,
        "GET",
        null,
        true
      );
      SearchSelector(data).length > 0 && setShowAutoCoplete(true);
      setAutocompleteData(SearchSelector(data));
    } catch (err) {
      // console.log("Err", err);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (searchTerm.length > 2) {
      searchHandler();
    } else {
      setAutocompleteData([]);
      setShowAutoCoplete(false);
    }
  }, [searchTerm, searchHandler]);

  const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
  };

  const onFocusHandler = () => {
    if (autocompleteData.length > 0) {
      return setShowAutoCoplete(true);
    }
    setShowAutoCoplete(false);
  };

  const redirectHandler = (item: any) => {
    if (item.key === "camp") {
      return `/kamp-alani/${item.slug}`;
    } else if (item.key === "cities") {
      return `/kamp-alanlari?city=${item.city}`;
    } else {
      return `/kamp-alanlari?region=${item.regionId}`;
    }
  };

  return (
    <Container
      ref={searchRef}
      scrollPosition={scrollPosition}
      isHome={pathname === "/"}
    >
      <InnerContainer id="search">
        <SearchInput
          placeholder="Şehir, Bölge, Alan Ara"
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          value={searchTerm}
        />
        {/* <SearchButtonContainer onClick={searchHandler}>
          <SearchButtonIcon icon={faSearch} />
          <SearchButtonText>Search</SearchButtonText>
        </SearchButtonContainer> */}
        <SearchButtonIcon icon={faSearch} />
      </InnerContainer>

      {showAutoComplete && (
        <AutoCompleteContainer
          scrollPosition={scrollPosition}
          isHome={pathname === "/"}
        >
          {autocompleteData?.map((item: any, index: number) => (
            <a href={redirectHandler(item)} key={index}>
              <AutoCompleteItem onClick={closeAutoComplete}>
                <AutoCompleteIconContainer>
                  <AutoCompleteIcon
                    icon={item.key === "camp" ? faCampground : faMapMarkerAlt}
                  />
                </AutoCompleteIconContainer>
                <AutoCompleteText>{item.title || item.city}</AutoCompleteText>
              </AutoCompleteItem>
            </a>
          ))}
        </AutoCompleteContainer>
      )}
    </Container>
  );
};

export default memo(Search);

const Container = styled.div<SCProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${(props) => props.theme.size.tablet}) {
    padding-left: 0px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #dddddd;
  border-radius: 32px;
  background-color: white;
  width: 100%;
  height: 65px;
  outline: none !important;
  box-shadow: 0px 16px 32px rgb(0 0 0 / 15%), 0px 3px 8px rgb(0 0 0 / 10%) !important;
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  margin: 0px 32px;
  width: 100%;
`;

// const SearchButtonContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 5px 30px;
//   margin: 7px 7px 7px 0px;
//   border-radius: 32px;
//   background-color: ${(props) => props.theme.main.primary};
// `;

// const SearchButtonText = styled.span`
//   font-size: 14px;
//   color: #303030;
//   @media (max-width: ${(props) => props.theme.size.tablet}) {
//     display: none;
//   }
// `;

const SearchButtonIcon = styled(FontAwesomeIcon)`
  width: 20px;
  margin-right: 25px;
  color: ${(props) => props.theme.main.primary};
`;

const AutoCompleteContainer = styled.div<SCProps>`
  position: absolute;
  background-color: white;
  top: ${(props) =>
    props.scrollPosition > 50 || !props.isHome ? "60px" : "80px"};
  border-radius: 32px;
  padding: 8px 0px;
  box-shadow: 0px 6px 20px rgb(0 0 0 / 20%) !important;
  max-height: 300px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-scrollbar0width: none;
  margin: 0px 30px
  &::-webkit-scrollbar {
    display: none;
  }
  width: ${(props) =>
    props.scrollPosition > 50 || !props.isHome ? "70%" : "90%"};
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    width: 90%;
  }
  *{
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
      display: none;
  }
`;

const AutoCompleteItem = styled.div`
  cursor: pointer;
  padding: 5px 32px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: rgb(241, 241, 241);
  }
`;

const AutoCompleteText = styled.span`
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: rgb(34, 34, 34) !important;
`;

const AutoCompleteIconContainer = styled.div`
  padding: 10px;
  background-color: rgb(241, 241, 241) !important;
  border: 1px solid rgba(176, 176, 176, 0.2) !important;
  border-radius: 8px !important;
  margin-right: 16px;
`;

const AutoCompleteIcon = styled(FontAwesomeIcon)`
  width: 15px;
  height: 15px;
`;
