import styled from "styled-components";
import Head from "next/head";
import { ToastContainer } from "react-toastify";

import useStore from "hooks/useStore";
import Footer from "components/Footer";
import Login from "components/Login";
import TabBar from "components/TabBar";
import Header from "./Header";

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  const { cookieSeen, setCookieSeen } = useStore();

  const setCookieSeenHandler = () => setCookieSeen(true);

  const CookieInfoBox = () => {
    return (
      <CookieContainer>
        <CookieTitle>Çerezler ve Politikalar</CookieTitle>
        <CookieText>
          Web sitesi trafiğini analiz etmek ve web sitesi deneyiminizi optimize
          etmek amacıyla çerezler kullanıyoruz. Çerez kullanımımızı kabul
          ettiğinizde, verileriniz tüm diğer kullanıcı verileriyle birlikte
          derlenir.
        </CookieText>
        <CookieButton onClick={setCookieSeenHandler}>Kabul Et</CookieButton>
      </CookieContainer>
    );
  };

  return (
    <div id="main">
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=yes"
        />
        <meta
          httpEquiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        />
      </Head>
      <InfoHeader>
        <span>{`Kampp mobil uygulaması App Store ve Google Play Store'da!`}</span>
        <AppContanier>
          <ALink
            aria-label="App Store"
            rel="noopener"
            href="https://apps.apple.com/us/app/kampp/id1269000420"
            target="_blank"
          >
            <StoreIcon
              src="/images/app_store.png"
              alt="appstore"
              width="100"
              height="50"
            />
          </ALink>
          <ALink
            aria-label="Play Store"
            rel="noopener"
            href="https://play.google.com/store/apps/details?id=com.kamp"
            target="_blank"
          >
            <StoreIcon
              src="/images/google_play.png"
              alt="playstore"
              width="100"
              height="50"
            />
          </ALink>
        </AppContanier>
      </InfoHeader>
      <Header />
      <div>{children}</div>
      <Footer />
      <Login />
      <ToastContainer />
      <TabBar />
      {!cookieSeen && CookieInfoBox()}
    </div>
  );
};

const CookieContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 24px;
  right: 10px;
  max-width: 400px;
  max-height: 400px;
  background-color: #1d211d;
  padding: 24px;
  border-radius: 4px;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    max-width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0px;
  }
`;
const CookieTitle = styled.span`
  font-size: 20px;
  color: white;
  font-weight: bold;
  margin-bottom: 16px;
`;
const CookieText = styled.span`
  font-size: 16px;
  color: white;
  font-weight: normal;
  line-height: 20px;
  margin-bottom: 24px;
`;
const CookieButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  font-size: 16px;
  font-weight: bold;
  border-radius; 4px;
  padding: 10px 10px;
  cursor: pointer;

`;

const InfoHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(240, 240, 240);
  padding: 12px 15%;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    padding: 12px 30px;
    display: none;
  }
`;

const AppContanier = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 24px;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    flex-direction: column;
  }
`;

const ALink = styled.a``;

const StoreIcon = styled.img`
  width: 120px;
  height: auto;
  margin-left: 10px;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    // margin-top: 10px;
  }
`;

export default Layout;
