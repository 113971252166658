import styled from "styled-components";
import Link from "next/link";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faChevronLeft, faUser } from "@fortawesome/free-solid-svg-icons";
import { useRouter } from "next/router";

import useStore from "hooks/useStore";
import { useViewport } from "hooks/useViewport";
import Search from "components/Search";
import ProfilePicture from "components/ProfilePicture";

//@ts-ignore
library.add(fab);

interface HeaderProps {
  scrollPosition: number;
  isHome?: boolean;
}

const Header = () => {
  const { scrollOffset } = useViewport();
  const { pathname, back } = useRouter();
  const { toggleLoginModal, user } = useStore();

  const routerBack = () => {
    back();
  };

  return (
    <>
      <Container scrollPosition={scrollOffset} isHome={pathname === "/"}>
        <Link href="/" passHref>
          <Logo
            src={
              scrollOffset > 50 || pathname !== "/"
                ? "/logo.png"
                : "/logo_secondary.png"
            }
            alt="header-logo"
            width="1600"
            height="900"
          />
        </Link>
        <NavContainer scrollPosition={scrollOffset} isHome={pathname === "/"}>
          <Link href="/kamp-alanlari" passHref>
            <NavTitle>Alanlar</NavTitle>
          </Link>
          <Link href="/makaleler" passHref>
            <NavTitle>Makaleler</NavTitle>
          </Link>
          <Link href="/etkinlikler" passHref>
            <NavTitle>Etkinlikler</NavTitle>
          </Link>
          {/* <NavTitle>Etkinlikler</NavTitle> */}
        </NavContainer>
        <SearchContainer
          scrollPosition={scrollOffset}
          isHome={pathname === "/"}
        >
          {pathname !== "/" && (
            <BackIcon icon={faChevronLeft} onClick={routerBack} />
          )}
          <Search scrollPosition={scrollOffset} />
        </SearchContainer>

        {user?.id ? (
          <ProfilePicture user={user} />
        ) : (
          <UserContainer
            scrollPosition={scrollOffset}
            isHome={pathname === "/"}
            onClick={toggleLoginModal}
          >
            <UserIcon icon={faUser} />
          </UserContainer>
        )}
      </Container>
      <DuplicateHeaderForPosition
        scrollPosition={scrollOffset}
        isHome={pathname === "/"}
      />
      <HeaderContainer scrollPosition={scrollOffset} isHome={pathname === "/"}>
        <Search />
      </HeaderContainer>
    </>
  );
};

const Container = styled.div<HeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15%;
  height: 80px;
  background-color: black;
  width: 100%;
  top: 0;
  left: 0;
  position: ${(props) =>
    props.scrollPosition > 50 || !props.isHome ? "fixed" : "relative"};
  background-color: ${(props) =>
    props.scrollPosition > 50 || !props.isHome ? "white" : "black"};
  transition: background-color 100ms linear;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px !important;
  z-index: 10;
  margin: 0 auto;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    padding: 0px 30px;
  }
}
`;

const DuplicateHeaderForPosition = styled.div<HeaderProps>`
  height: 80px;
  display: ${(props) => (props.scrollPosition > 50 ? "block" : "none")};
`;

const Logo = styled.img`
  cursor: pointer;
  width: 100px;
  height: auto;
  object-fit: contain;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    display: none;
  }
`;

const NavContainer = styled.div<HeaderProps>`
  display: ${(props) =>
    props.scrollPosition > 50 || !props.isHome ? "none" : "block"};
  span {
    color: ${(props) =>
      props.scrollPosition > 50 || !props.isHome ? "black" : "white"};
  }
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    display: none;
  }
`;

const NavTitle = styled.span`
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  padding: 10px 2px;
  margin: 0px 10px;
  &:hover {
    border-bottom: 1px solid #fff;
  }
`;

const UserContainer = styled.div<HeaderProps>`
  padding: 8px 10px;
  cursor: pointer;
  border: 1px solid #dddddd;
  border-radius: 50px;
  background-color: white;
  svg {
    // color: ${(props) =>
      props.scrollPosition > 50 || !props.isHome ? "#666666" : "white"};
    color: #666666;
  }
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    display: none;
  }
`;

const UserIcon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
`;

const BackIcon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  display: none;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    display: block;
  }
`;

const SearchContainer = styled.div<HeaderProps>`
  flex: 1;
  display: ${(props) =>
    props.scrollPosition > 50 || !props.isHome ? "flex" : "none"};
  align-items: center;
  #search {
    height: 50px;
    width: 70%;
  }
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    #search {
      height: 50px;
      width: 100%;
    }
  }
`;

const HeaderContainer = styled.div<HeaderProps>`
  background-color: ${(props) => (!props.isHome ? "whit" : "black")};
  padding-bottom: ${(props) => (!props.isHome ? "0px" : "24px")};
  display: ${(props) =>
    props.scrollPosition > 50 || !props.isHome ? "none" : "block"};
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    z-index: 10;
    margin-bottom: 40px;
    #search {
      height: 50px;
      width: 100%;
      margin: 0px 30px;
    }
  }
  div {
    // Search Component
    opacity: ${(props) =>
      props.scrollPosition > 50 || !props.isHome ? "0" : "1"};
  }
`;

// transform: ${(props) => (props.scrollPosition > 20 ? "translateY(-5em)" : "translateY(0em)")};

export default Header;
