import styled from "styled-components";
import Link from "next/link";

const ProfilePicture = ({ user }: any) => {
  const ProfileIconSrc = user.facebookId
    ? `https://graph.facebook.com/${user.facebookId}/picture?type=normal`
    : user.profilePicture || "/images/profile.png";

  return (
    <Link href="/profile" passHref>
      <ProfileIcon
        hasBorder={ProfileIconSrc.includes('images')}
        src={ProfileIconSrc}
        width={20}
        height={20}
        alt="profile_image"
      />
    </Link>
  );
};

const ProfileIcon = styled.img<{hasBorder: boolean}>`
  width: 40px;
  height: 40px;
  object-fit: contain;
  overflow: hidden;
  border-radius: 50px;
  border: ${(props) => props.hasBorder ? '1px solid #DDDDDD' : ''};
  padding: ${(props) => props.hasBorder ? '5px' : '0px'};
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    display: none;
  }
`;

export default ProfilePicture;